import './App.css';
import DataTable from './components/DataTable';

function App() {
    return (
        <DataTable />
    );
}

export default App;
